<template>
  <div id="orderListBuyer" style="border: 0px solid #ddd; padding: 10px; background: white;" class="buyerodrlist">
    <div class="row">
      <div class="col-md-12 order-list-top">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h2 class="title">Dispute & Return</h2>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="row" style="margin-top: 15px; margin-bottom: 10px;">
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                <input
                  type="text"
                  style="font-size: 12px;"
                  v-model="searchKey"
                  class="form-control"
                  id="input-store"
                  placeholder="Search Order"
                  value
                  name="voucher"
                />
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="padding-left: 0px;">
                <button
                  type="button"
                  class="btn merchprof-sbtn"
                  style="min-height: 20px;"
                  @click="getOrders()"
                  :disabled="searchKey== ''"
                >Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="table-content" class="col-md-12 col-sm-12 buyerord--list">
        <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
          <table id="example" class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="head-table"></th>
                <th scope="col" class="head-table">Order</th>
                <th scope="col" class="head-table" style="text-align: center;">Date</th>
                <!-- <th scope="col" class="head-table" style="text-align: center;">Status</th> -->
                <th scope="col" class="head-table" style="text-align: center;">Dispute Status</th>
                <th scope="col" class="head-table" style="text-align: center;">Amount</th>
                <th scope="col" class="head-table" style="text-align: left;">Payment Method</th>
                <th scope="col" class="head-table" style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
              <div class="col-md-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </div>
              </div>
              <tr>
                <td
                  colspan="6"
                  class="text-center norec col-md-12"
                  v-if="blockLoading==false && tableItems.length==0"
                >No Records Found!</td>
              </tr>
              <tr v-for="(item, index) in tableItems" :key="index">
                <td style="vertical-align: middle;" class="text-center col-md-1">
                  <a>
                    <img
                      style="width:70px; height: 70px;"
                      :src="item.simple_product_id.images[0].image"
                      class="img-thumbnail"
                    />
                  </a>
                </td>

                <td class="text-left" style="vertical-align: middle;">
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <span style="font-size: 12px;">
                        <b>{{ item.order_env_number }}</b>
                      </span>
                      <br />
                    </div>
                  </div>
                </td>

                <td
                  class="text-center col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                >{{ item.date }}</td>
                
                <td class="text-center" style="vertical-align: middle;">
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                       <span class="badge" style="background-color: rgb(241, 202, 22); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                          v-if="item.dispute_status === 1">
                        <B>Dispute Opened</B>
                      </span>

                      <span class="badge" style="background-color: rgb(255, 163, 0); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                          v-if="item.dispute_status === 2">
                        <B>Dispute in Progress</B>
                      </span>

                      <span class="badge" style="background-color: green;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                          v-if="item.dispute_status === 10">
                        <B>Merchant Approved</B>
                      </span>

                      <span class="badge" style="background-color: red;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                          v-if="item.dispute_status === 20">
                        <B>Merchant Rejected</B>
                      </span>

                      <span class="badge" style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                          v-if="item.dispute_status === 30">
                        <B>Escalated To Cochchi Support</B>
                      </span>

                      <span class="badge" style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                          v-if="item.dispute_status === 31">
                        <B>Cochchi Support in Progress</B>
                      </span>

                      <span class="badge" style="background-color: green;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                          v-if="item.dispute_status === 40">
                        <B>Cochchi Support Approved</B>
                      </span>

                      <br />
                    </div>
                  </div>
                </td>

                <td
                  class="text-center col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                >{{"Rs:" + item.order_line_id.total_charge}}</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.order_line_id.payment_type === 1"
                >Supiri Pola Credit</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.order_line_id.payment_type === 2"
                >Cash On Delivery</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.order_line_id.payment_type === 3"
                >Bank Transfer</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.order_line_id.payment_type === 4"
                >Card Payment</td>
                <td style="vertical-align: middle;">
                  <button class="btn btn-primary" @click="handdleChange(item)"> view </button>
                  <button 
                    v-if="item.dispute_status === 20"
                    class="btn btn-sm merchprof-send-to-admin-sbtn" 
                    @click="haddleSendToAdminModalOpen(item)">
                  Contact Cochchi Support</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row text-right" style="margin-bottom: -20px; margin-top: -20px;">
        <div class="col-sm-12">
          <div>
            <b-pagination
              v-model="currentPage"
              ref="paginationCurrentPage"
              :per-page="pageSize"
              :total-rows="rowcount"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

     <!-- merchant accept modal start-->
     <div
      style="padding-top: 3%;"
      class="modal fade"
      id="trackMerchantAcceptModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Send your dispute to Cochchi Support</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 order-list-top">
               
                <div class="form-group required">
                <label>Reason</label>

                <textarea
                  rows="10"
                  type="text"
                  placeholder="Description"
                  class="form-control"
                  style="min-height: 80px;"
                  v-model="user_reason"
                />
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary buyerprof-sbtn" data-dismiss="modal">Close</button>
              <button class="btn btn-primary merchprof-sbtn" @click="haddleMerchantAccept()" :disabled="user_reason === ''">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- merchant accept modal end-->

  </div>
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder";
import mixinProduct from "@/mixins/buyer/APIProduct";
export default {
  mixins: [mixinOrder, mixinProduct],
  components: {},
  data() {
    return {
      breadcrumb: [
        {
          text: "Return Order List",
          active: true
        }
      ],
      selectedArray: [],
      pageSize: 5,
      currentPage: 1,
      conditionCp: 1,
      rowcount: 0,
      tableItems: [],
      blockLoading: true,
      user_reason: "",
      user_reasonId: "",
      searchKey: ""
    };
  },
  watch: {
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
        // this.paginationStyleFunction(
        //   this.$refs.paginationCurrentPage.$refs.ul.children
        // );
      }
    },
    searchKey: function(val) {
      if (val === "") {
        this.getOrders();
      }
    }
  },
  created() {
    this.handleBreadcrumb();
    this.getOrders();
    this.checkCardPaymentSuccess();
  },
  // mounted() {
  //   this.paginationStyleFunction(
  //     this.$refs.paginationCurrentPage.$refs.ul.children
  //   );
  // },
  methods: {
    paginationStyleFunction(array) {
      if (array === "") {
        array = this.$refs.paginationCurrentPage.$refs.ul.children;
      }
      if (this.conditionCp !== this.currentPage) {
        for (let x = 0; x < array.length; x++) {
          if (array[x].innerText === this.conditionCp.toString()) {
            array[x].children[0].style.border = "";
            array[x].children[0].style.background = "";
            array[x].children[0].style.color = "black";
          }
        }
        this.conditionCp = this.currentPage;
      }
      for (let x = 0; x < array.length; x++) {
        if (array[x].innerText === this.currentPage.toString()) {
          array[x].children[0].style.border = "2px solid #337ab7";
          array[x].children[0].style.background = "#337ab7";
          array[x].children[0].style.color = "black";
        }
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrders: async function() {
      try {
        this.blockLoading = true;
        // let responce = await this.getAllOrdersForAUser();
        let params = "page="+ this.currentPage;
        params += "&perpage=" + this.pageSize;
        params += "&order_no=" + this.searchKey;
        let responce = await this.getReturnOrder(params);
        let res1 = await this.getReturnOrderCount(params);
        this.tableItems = responce;
        this.rowcount = res1.result;
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      this.currentPage = currentPage;
      this.getOrders();
    },
    goToView: function(item) {
      this.$router.push({
        name: "Buyer Order Configure",
        params: { mId: item.merchant._id, id: item.order_env_number }
      });
    },
    // payment gateway function
    checkCardPaymentSuccess: function() {
      var order_env_number = getUrlParameterByName("order_env_number");
      var version = getUrlParameterByName("sessionVersion");
      var success_indicator = getUrlParameterByName("resultIndicator");
      if (order_env_number && version && success_indicator) {
        // success
        var obj = {
          order_env_number: order_env_number,
          status: true,
          version: version,
          success_indicator: success_indicator
        };
        var responce = this.saveCardPaymentSuccess(obj);
        this.getOrders();
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "ORDER PLACED",
          showConfirmButton: false,
          timer: 1500
        });
      } else if (
        order_env_number &&
        (version == null || success_indicator == null)
      ) {
        var obj = { order_env_number: order_env_number, status: false };
        var responce = this.saveCardPaymentSuccess(obj);
        this.getOrders();
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Payment Not Completed",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    haddleSendToAdminModalOpen: async function(item) {
      this.user_reasonId = item._id;
      $("#trackMerchantAcceptModalCenter").modal("show");
    },
    handdleChange: function(item) {
      this.$router.push({
        name: "Buyer Return Order Configure View",
        params: { id: item.order_env_number }
      });
    },
    haddleMerchantAccept: async function() {
      var statusObj = {};

      statusObj._id = this.user_reasonId;
      statusObj.user_reason = this.user_reason;

      try {
        await this.putDisputeOderBuyerSendToAdmin(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.getOrders();
        $("#trackMerchantAcceptModalCenter").modal("hide");
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
<style>
#orderListBuyer {
  position: relative;
}

.pagination li button {
  padding: 1px 12px;
  min-height: 1.42857143;
}
.pagination li.active > button {
  border: 2px solid #5779ae;
  background: #5779ae none repeat scroll 0% 0%;
  color: white !important;
}
.pagination > li > span {
  line-height: auto;
}
.pagination > li > span {
  float: none;
}

#orderListBuyer .vdp-datepicker input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
#orderListBuyer .sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
#orderListBuyer .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
#orderListBuyer .sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color:#5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
#orderListBuyer .sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
#orderListBuyer .sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
#orderListBuyer .order-list-top {
  padding-top: 10px;
}
#orderListBuyer .search-prd-block {
  text-align: right;
}
#orderListBuyer .search-prd-block input[type="text"] {
  display: inline-block;
  width: 50%;
}
#orderListBuyer .filter-block select {
  display: inline-block;
  width: 75%;
  height: 35px !important;
}
#orderListBuyer .filter-block button {
  float: right;
}
#orderListBuyer .from-to-block {
  padding-top: 15px;
}
#orderListBuyer .pagination {
  margin-right: 20px;
}
.buyerodrlist .buyerprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.buyerodrlist .buyerprof-sbtn:hover {
  color: #000000;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  /* top: 0; */
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.merchprof-send-to-admin-sbtn {
  background:#ffa300;
  color: white;
  border: none;
  margin-top: 5px;
}
.merchprof-send-to-admin-sbtn:hover {
  color: #000000;
}

@media screen and (max-width: 1348px) {
  table {
    width: 960px !important;
  }
  .img-thumbnail {
    max-width: 80px;
  }
}
@media screen and (max-width: 1275px) {
  .buyerord--list{
    max-width: 1000px;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1200px) {
  #orderListBuyer .search-prd-block input[type="text"] {
    width: 100%;
  }
  #orderListBuyer .filter-block select {
    width: 90%;
  }
}
@media screen and (max-width: 725px) {
  #orderListBuyer .filter-block select {
    width: 80%;
  }
}
@media screen and (max-width: 625px) {
  #orderListBuyer {
    border: none !important;
  }
}
@media screen and (max-width: 560px) {
  #orderListBuyer .from-block,
  .to-block {
    width: 100%;
  }
  #orderListBuyer .from-to-block .dp-adjust {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  #orderListBuyer .filter-block select {
    width: 70%;
  }
}
@media (max-width: 425px) {
  .nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    margin-bottom: 6px;
    border: 1px solid #555;
    border-radius: 2px;
  }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    margin-right: 4px;
  }
  .norec {
    text-align: left;
  }
}
</style>